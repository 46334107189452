import Vue from "vue";
import Options from "vue-class-component";
import AdminLayout from "@/layouts/Admin/index.vue";
@Options({
    components: {
      AdminLayout,
    }
})
export default class GroupMembers extends Vue {
    active = false
    loading = false
    totalItems = 0
}